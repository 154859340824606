import { useCallback, useEffect, useState } from 'react';
import { useNotifications } from './NotificationsContext';
import type { NotificationDetails, NotificationHandle } from './types';
import { MuiSnackbar } from 'shared/elements/Snackbar/MuiSnackbar';

export function NotificationsSnackbar() {
  const { notifications } = useNotifications();
  const [currentNotification, setCurrentNotification] = useState<NotificationHandle | undefined>(undefined);

  useEffect(() => {
    setCurrentNotification(prev => {
      if (notifications.some(({ id }) => id === prev)) {
        return prev;
      }
      return notifications[0]?.id;
    });
  }, [notifications]);

  return notifications.map(({ id, message, vibe, action }) => (
    <SnackbarDisplay key={id} id={id} message={message} vibe={vibe} action={action} open={id === currentNotification} />
  ));
}

function SnackbarDisplay({ id, message, vibe, action, open }: NotificationDetails & { open: boolean }) {
  const { clear } = useNotifications();

  const handleClose = useCallback(() => {
    clear(id);
  }, [id, clear]);

  return <MuiSnackbar open={open} onClose={handleClose} message={message} type={vibe} action={action} />;
}
