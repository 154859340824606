import { type PaletteMode, ThemeProvider } from '@mui/material';
import { type FC, type RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { muiDarkTheme } from '../../theme/darkTheme';
import { muiLightTheme } from '../../theme/lightTheme';
import { ConfigContext } from './ConfigContext';
import { oldLightTheme } from './oldLightTheme';
import { oldDarkTheme } from './oldDarkTheme';

export interface ConfigProviderProps {
  children?: React.ReactNode;
  reference?: RefObject<any> | HTMLElement;
}

const isRefObject = (ref: unknown) =>
  ref !== null && typeof ref === 'object' && Object.prototype.hasOwnProperty.call(ref, 'current');

export const ConfigProvider: FC<ConfigProviderProps> = ({ children, reference = document.body }) => {
  const [initialUserTheme, setInitialUserTheme] = useLocalStorage<PaletteMode>('@query/theme', 'dark');

  // We have to proxy this through state because calling setInitialUserTheme to change
  // the theme will not result in a re-render (because local storage changes don't emit
  // an event to react to).
  const [userTheme, setUserTheme] = useState<PaletteMode>(initialUserTheme ?? 'dark');
  const setTheme = useCallback(
    (newTheme: PaletteMode) => {
      setUserTheme(newTheme);
      setInitialUserTheme(newTheme);
    },
    [setInitialUserTheme]
  );

  const activeTheme = useMemo(() => (userTheme === 'dark' ? muiDarkTheme : muiLightTheme), [userTheme]);

  // This is a legacy effect that only exists to set the correct CSS variables based on light/dark theme for the
  // old theme that is not being used by any of our MUI components.  Currently the only page still using this
  // theme is the Welcome page.
  useEffect(() => {
    const oldThemeVars = userTheme === 'dark' ? oldDarkTheme : oldLightTheme;
    const element = isRefObject(reference) ? (reference as RefObject<any>).current : reference;

    for (const prop in oldThemeVars.oldVariables) {
      element.style.setProperty(prop, oldThemeVars.oldVariables[prop]);
    }

    for (const prop in oldThemeVars.oldColors) {
      for (const k in oldThemeVars.oldColors[prop]) {
        element.style.setProperty(`--color-${prop}-${k}`, oldThemeVars.oldColors[prop][k]);
      }
    }
  }, [reference, userTheme]);

  const values = useMemo(
    () => ({
      name: userTheme,
      setTheme,
    }),
    [userTheme, setTheme]
  );

  return (
    <ConfigContext.Provider value={values}>
      <ThemeProvider theme={activeTheme}>{children}</ThemeProvider>
    </ConfigContext.Provider>
  );
};
