import { gql, useApolloClient, useQuery } from '@apollo/client';
import { User, useAuth0 } from '@auth0/auth0-react';
import { FullStory } from '@fullstory/browser';
import { getCurrentScope } from '@sentry/react';
import { type QueryUsersPayload, TeamMembershipRole } from 'core/metadata-graphql/types';
import { type FC, type PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { type IntercomProps, useIntercom } from 'react-use-intercom';
import { AuthProvider } from './AuthContext';
import { getString } from 'core/utils/saferGet';
import { useNotifications } from 'shared/layers/Notification/NotificationsContext';

const GET_USER = gql`
  query usersByEmail($email: [String!]!) {
    usersByEmail(email: $email) {
      errors
      records {
        auth0UserId
        createdAt
        email
        firstName
        fullName
        id
        lastLoginTime
        lastName
        metadata
        teams {
          id
          name
          role
          organizationName
        }
        tenantId
        userType
        username
      }
    }
  }
`;

class QueryUser extends User {
  ['https://metadata.api/userAndTeam']?: {
    userId: `${number}`;
    tenantId: `${number}`;
    teamId: `${number}`;
    name: string;
    email: string;
    role: `${TeamMembershipRole}`;
    teamName: string;
  };
}

export const Auth: FC<PropsWithChildren> = ({ children }) => {
  const client = useApolloClient();
  const {
    logout: auth0Logout,
    loginWithRedirect,
    user: auth0User,
    isLoading: auth0IsLoading,
    error,
  } = useAuth0<QueryUser>();
  const { boot } = useIntercom();
  const { notify } = useNotifications();

  const canResetPassword = auth0User?.sub?.startsWith('auth0|');

  const {
    loading: userLoading,
    data: users,
    refetch: refetchUser,
  } = useQuery<{ usersByEmail: QueryUsersPayload }>(GET_USER, {
    variables: { email: [auth0User?.email] },
    skip: !auth0User?.email,
  });

  const [user] = users?.usersByEmail?.records || [];
  const roleFromApi = user?.teams[0]?.role;
  // If the user has a role on the id token, we should only consider them an admin if the API and
  // the token are in agreement. Prior to this change, newly-assigned admins would fail to make
  // calls because their existing token would not have the new role.
  const roleOnIdToken = auth0User?.['https://metadata.api/userAndTeam']?.role;
  const hasAdminRole = roleOnIdToken
    ? roleFromApi === TeamMembershipRole.Admin && roleOnIdToken === TeamMembershipRole.Admin
    : roleFromApi === TeamMembershipRole.Admin;

  useEffect(() => {
    async function setupUser() {
      if (import.meta.env.PROD) {
        FullStory('setIdentity', {
          uid: user.email,
          properties: {
            displayName: user.fullName,
            email: user.email,
            company: getString(user, 'metadata.company'),
            title: getString(user, 'metadata.title'),
            createdAt: user.createdAt,
          },
        });

        getCurrentScope().setUser({
          username: user.email,
          name: user.fullName,
          email: user.email,
        });

        boot({
          hideDefaultLauncher: true,
          company: {
            companyId: getString(user, 'metadata.company'),
            name: getString(user, 'metadata.company'),
          },
          title: getString(user, 'metadata.title'),
          email: user.email,
          name: user.fullName,
          createdAt: `${new Date(user.createdAt).getTime()}`,
        } as Partial<IntercomProps>);
      }
    }

    // If we got the auth0 user and we are not already loading the user
    if (user) {
      setupUser();
    }
  }, [boot, user]);

  const logout = useCallback(async () => {
    client.clearStore();
    auth0Logout({
      logoutParams: {
        returnTo: `${window.location.origin}/login`,
      },
    });
  }, [client, auth0Logout]);

  const resetPassword = useCallback(
    async (email: string) => {
      try {
        await fetch(`https://${import.meta.env.VITE_AUTH0_DOMAIN}/dbconnections/change_password`, {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
            email,
            connection: 'Username-Password-Authentication',
          }),
        });
        notify({
          message: `Password reset instructions were sent to ${email}`,
          vibe: 'positive',
        });
      } catch {
        notify({
          message: 'Something unexpected happened trying to reset your password',
          vibe: 'informative',
        });
      }
    },
    [notify]
  );

  const values = useMemo(
    () => ({
      user,
      hasAdminRole,
      login: loginWithRedirect,
      canResetPassword,
      isLoading: auth0IsLoading || userLoading,
      logout,
      resetPassword,
      refetchUser,
      error,
    }),
    [
      user,
      hasAdminRole,
      loginWithRedirect,
      canResetPassword,
      userLoading,
      auth0IsLoading,
      logout,
      resetPassword,
      refetchUser,
      error,
    ]
  );

  return <AuthProvider value={values}>{children}</AuthProvider>;
};
