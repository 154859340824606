import { Link as MuiLink, Typography } from '@mui/material';
import LogoDark from 'assets/brand/logo-dark.svg?react';
import Logo from 'assets/brand/logo.svg?react';
import ErrorIllustration from 'assets/illustrations/empty.svg?react';
import { type FC, useEffect } from 'react';
import { useConfig } from 'shared/utils/ConfigProvider/ConfigContext';
import type { FallbackProps } from 'react-error-boundary';
import css from './ErrorComponent.module.css';
import { Link as RouterLink } from 'react-router-dom';
import { ErrorDevDisplay } from './ErrorDevDisplay';

export const ErrorComponent: FC<FallbackProps> = ({ error }) => {
  const { name } = useConfig();
  const isRefresh = error?.toString()?.indexOf('Chunk') > -1;

  useEffect(() => {
    if (isRefresh) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }, [isRefresh]);

  return isRefresh ? (
    <div className={css.container}>
      <Typography variant="h1">Updating</Typography>
    </div>
  ) : (
    <div className={css.error}>
      {name === 'dark' ? <LogoDark className={css.logo} /> : <Logo className={css.logo} />}
      <Typography variant="h1">Error</Typography>
      <Typography variant="h2">Something bad happened</Typography>
      <br />
      <Typography>Here are some helpful links instead:</Typography>
      <div className={css.quickLinks}>
        <MuiLink href="https://www.query.ai/">Query.ai</MuiLink>
        <MuiLink component={RouterLink} to="/search">
          Search Home
        </MuiLink>
        <MuiLink href="https://www.query.ai/resources/">Resources</MuiLink>
        <MuiLink href="https://www.query.ai/contact-us-support/">Contact</MuiLink>
      </div>
      <ErrorIllustration className={css.errorIllustration} />
      <ErrorDevDisplay error={error} />
    </div>
  );
};
