/**
 * A tuple of colors used for a single color set, with variants for normal, hover, and active states.
 */
export type ColorTuple = readonly [
  /**
   * Referenced as "default/active" in figma, this is the color used for the default state of
   * elements using this color set.
   */
  normal: string,
  /**
   * Referenced as "hover" in figma, this is the color used for the hover state of elements using
   * this color set.
   */
  hover: string,
  /**
   * Referenced as "pressed" in figma, this is the color used for the active state of elements using
   * this color set.
   */
  active: string,
];

// ***LIGHT Colors***

// SEMANTIC
// Feedback
export const LIGHT_NEGATIVE = '#DA0711';
export const LIGHT_CAUTION = '#AB8A17';
export const LIGHT_POSITIVE = '#35A151';
export const LIGHT_INFO = '#5A068E';
// Feedback Background
export const LIGHT_FEEDBACK_BG_NEUTRAL = '#E3E3E3';

// SURFACE
export const LIGHT_RULES = '#C9C9C9';
export const LIGHT_CONTAINER_BORDERS = '#EAEAEA';
export const LIGHT_PAGE_BACKGROUND = '#FAFAFA';
export const LIGHT_CONTAINER_BACKGROUND = '#FFFFFF';
export const LIGHT_MODAL_BACKGROUND = '#F6F6F6';
export const LIGHT_TABLE_ROW_BACKGROUND = '#F5F5F5';
export const LIGHT_TOOLTIP_BACKGROUND = '#424242';
const _LIGHT_SCRIM_OVERLAY = 'rgba(18,18,18,.5)';
export const LIGHT_NEGATIVE_BACKGROUND = '#F8CDCF';
export const LIGHT_CAUTION_BACKGROUND = '#F4EFC1';
export const LIGHT_INFO_BACKGROUND = '#DECDE8';

// TEXT
export const LIGHT_TEXT_PRIMARY = '#212121';
export const LIGHT_TEXT_SECONDARY = '#505050';
export const LIGHT_TEXT_TERTIARY = '#737373';
export const LIGHT_TEXT_BUTTON = '#FAFAFA';
export const LIGHT_TEXT_TOOLTIP = '#F5F5F5';
export const LIGHT_TEXT_DISABLED = '#9E9E9E';
export const LIGHT_TEXT_NEGATIVE = LIGHT_NEGATIVE;
const _LIGHT_TEXT_CAUTION = LIGHT_CAUTION;
const _LIGHT_TEXT_POSITIVE = LIGHT_POSITIVE;

// INTERACTIVE
export const LIGHT_INTERACTIVE_ACTIVE = '#13AFCA';
export const LIGHT_INTERACTIVE_PRIMARY_HOVER = '#118A9E';
export const LIGHT_INTERACTIVE_PRIMARY_PRESSED = '#146C7B';
export const LIGHT_INTERACTIVE_SECONDARY_HOVER = '#E6FBFF';
export const LIGHT_INTERACTIVE_SECONDARY_PRESSED = '#B8EEFF';
export const LIGHT_INTERACTIVE_SELECTED = '#F3F8FF';
const _LIGHT_INTERACTIVE_DESTRUCTIVE = LIGHT_NEGATIVE;
const _LIGHT_INTERACTIVE_DESTRUCTIVE_HOVER = '#8E0217';
const _LIGHT_INTERACTIVE_DESTRUCTIVE_PRESSED = '#670015';
export const LIGHT_INTERACTIVE_NEGATIVE_HOVER = '#F2A5A9';
export const LIGHT_INTERACTIVE_CAUTION_HOVER = '#E5DB9F';
export const LIGHT_INTERACTIVE_INFO_HOVER = '#C4A5D6';

// DATA VISUALIZATION
// Alert severity
export const LIGHT_SEVERITY_FATAL: ColorTuple = ['#CB3056', '#C81642', '#AE022C'];
export const LIGHT_SEVERITY_CRITICAL: ColorTuple = ['#DA0711', '#DA0711', '#EE280D'];
export const LIGHT_SEVERITY_HIGH: ColorTuple = ['#E86800', '#C95B02', '#AB4D01'];
export const LIGHT_SEVERITY_MEDIUM: ColorTuple = ['#AB8A17', '#AB8A17', '#765F0F'];
export const LIGHT_SEVERITY_LOW: ColorTuple = ['#538184', '#436B6E', '#325557'];
export const LIGHT_SEVERITY_INFO: ColorTuple = ['#538184', '#436B6E', '#325557'];
export const LIGHT_SEVERITY_OTHER: ColorTuple = ['#9E9E9E', '#858585', '#7B7B7B'];
// Categorical Palette
export const LIGHT_DATAVIS_01: ColorTuple = ['#05A0C3', '#018AA9', '#02738C'];
export const LIGHT_DATAVIS_02: ColorTuple = ['#5A068E', '#4B0279', '#3C0161'];
export const LIGHT_DATAVIS_03: ColorTuple = ['#BC108E', '#A00677', '#830161'];
export const LIGHT_DATAVIS_04: ColorTuple = ['#CC5C00', '#B25101', '#8F4101'];
export const LIGHT_DATAVIS_05: ColorTuple = ['#007713', '#015E10', '#00550E'];
export const LIGHT_DATAVIS_06: ColorTuple = ['#39d2f3', '#26B6D5', '#199CB8'];
export const LIGHT_DATAVIS_07: ColorTuple = ['#7A3700', '#5D2A00', '#431F00'];
export const LIGHT_DATAVIS_08: ColorTuple = ['#F94D56', '#E43A43', '#C8232C'];
export const LIGHT_DATAVIS_09: ColorTuple = ['#00550E', '#013A0A', '#002305'];
export const LIGHT_DATAVIS_10: ColorTuple = ['#BD5EF9', '#A347DD', '#832FB8'];
export const LIGHT_DATAVIS_11: ColorTuple = ['#041E62', '#001344', '#000920'];
export const LIGHT_DATAVIS_12: ColorTuple = ['#F056C7', '#DB45B3', '#BF3099'];
export const LIGHT_DATAVIS_13: ColorTuple = ['#710A55', '#54063F', '#340226'];
export const LIGHT_DATAVIS_14: ColorTuple = ['#0637B1', '#032A8D', '#02206A'];

export const LIGHT_DATA_VIS_DATA_ORANGE = LIGHT_DATAVIS_07[0];
export const LIGHT_DATA_VIS_DATA_BLUE = '#00554A';
export const LIGHT_DATA_VIS_DATA_VIOLET = LIGHT_DATAVIS_10[0];
export const LIGHT_DATA_VIS_DATA_VIOLET_HOVER = '#CD7EFF';
export const LIGHT_DATA_VIS_DATA_GREEN_50 = LIGHT_DATAVIS_05[0];
export const LIGHT_DATA_VIS_DATA_GREEN_70 = '#00550E';
export const LIGHT_DATA_VIS_DATA_GREEN_70_HOVER = '#0F5D1C';

export const LIGHT_DATA_VIS_TAB_BACKGROUND = '#E1E6E7';

// Charts & Graphs
export const LIGHT_AXIS_LINES = '#737373';
export const LIGHT_GRID_LINES = '#E0E0E0';
export const LIGHT_TITLES_VALUES = '#212121';

// ***DARK Colors***

// SEMANTIC
// Feedback
export const DARK_NEGATIVE = '#FF604A';
export const DARK_CAUTION = '#FAC354';
export const DARK_POSITIVE = '#17AE81';
export const DARK_INFO = '#817CF6';
// Feedback Background
export const DARK_FEEDBACK_BG_NEUTRAL = '#515151';

// SURFACE
export const DARK_RULES = '#737373';
export const DARK_PAGE_BACKGROUND = '#121212';
export const DARK_CONTAINER_BACKGROUND = '#1E1E1E';
export const DARK_MODAL_BACKGROUND = '#2E2E2E';
export const DARK_CONTAINER_BORDERS = '#424242';
export const DARK_TABLE_ROW_BACKGROUND = '#292929';
export const DARK_TOOLTIP_BACKGROUND = '#424242';
const _DARK_SCRIM_OVERLAY = 'rgba(18,18,18,.7)';
export const DARK_NEGATIVE_BACKGROUND = '#592923';
export const DARK_CAUTION_BACKGROUND = '#60502E';
export const DARK_INFO_BACKGROUND = '#464474';

// TEXT
export const DARK_TEXT_PRIMARY = '#F5F5F5';
export const DARK_TEXT_SECONDARY = '#E0E0E0';
export const DARK_TEXT_TERTIARY = '#9E9E9E';
export const DARK_TEXT_BUTTON = '#121212';
const _DARK_TEXT_TOOLTIP = '#F5F5F5';
export const DARK_TEXT_DISABLED = '#737373';
export const DARK_TEXT_NEGATIVE = DARK_NEGATIVE;
const _DARK_TEXT_CAUTION = DARK_CAUTION;
const _DARK_TEXT_POSITIVE = DARK_POSITIVE;

// INTERACTIVE
export const DARK_INTERACTIVE_ACTIVE = '#1EC1DD';
export const DARK_INTERACTIVE_PRIMARY_HOVER = '#39DAF5';
export const DARK_INTERACTIVE_PRIMARY_PRESSED = '#89ECFD';
export const DARK_INTERACTIVE_SECONDARY_HOVER = '#0D404B';
export const DARK_INTERACTIVE_SECONDARY_PRESSED = '#2A6B79';
export const DARK_INTERACTIVE_SELECTED = '#14333D';
export const DARK_INTERACTIVE_DESTRUCTIVE = DARK_NEGATIVE;
export const DARK_INTERACTIVE_DESTRUCTIVE_HOVER = '#FF847B';
export const DARK_INTERACTIVE_DESTRUCTIVE_PRESSED = '#FF9995';
export const DARK_INTERACTIVE_NEGATIVE_HOVER = '#7A342B';
export const DARK_INTERACTIVE_CAUTION_HOVER = '#7F6736';
export const DARK_INTERACTIVE_INFO_HOVER = '#524F8E';

// DATA VISUALIZATION
// Alert Severity
export const DARK_SEVERITY_FATAL: ColorTuple = ['#E12476', '#E7428A', '#EC5D9C'];
export const DARK_SEVERITY_CRITICAL: ColorTuple = ['#FF604A', '#FB7A68', '#FD8574'];
export const DARK_SEVERITY_HIGH: ColorTuple = ['#F28830', '#F29A52', '#F5B078'];
export const DARK_SEVERITY_MEDIUM: ColorTuple = ['#FAC354', '#FAD078', '#FFDF9B'];
export const DARK_SEVERITY_LOW: ColorTuple = ['#57969E', '#719EA4', '#86A8AC'];
export const DARK_SEVERITY_INFO: ColorTuple = ['#9B85AC', '#A887B4', '#B69FBF'];
export const DARK_SEVERITY_OTHER: ColorTuple = ['#9E9E9E', '#BBBBBB', '#D1D1D1'];
// Categorical Palette
export const DARK_DATA_VIS_DATA_ORANGE = '#F2BC82';
export const DARK_DATA_VIS_DATA_BLUE = '#B1EDEA';
export const DARK_DATA_VIS_DATA_VIOLET = '#B4B0FF';
export const DARK_DATA_VIS_DATA_GREEN = '#6DC6A1';

export const DARK_DATAVIS_01: ColorTuple = ['#5FD3F8', '#83DCF8', '#A1E2F6'];
export const DARK_DATAVIS_02: ColorTuple = ['#817CF6', '#A5A1FD', '#BBB8FE'];
export const DARK_DATAVIS_03: ColorTuple = ['#B4549A', '#BD6FA8', '#D190C0'];
export const DARK_DATAVIS_04: ColorTuple = ['#D68631', '#D99955', '#E3AF77'];
export const DARK_DATAVIS_05: ColorTuple = ['#6DC6A1', '#8ACFB2', '#A3D7C2'];
export const DARK_DATAVIS_06: ColorTuple = ['#1FA2CC', '#4FB6D7', '#76C9E4'];
export const DARK_DATAVIS_07: ColorTuple = ['#F2BC82', '#F6CB9E', '#F9DBBA'];
export const DARK_DATAVIS_08: ColorTuple = ['#F26B6B', '#F88181', '#FB9F9F'];
export const DARK_DATAVIS_09: ColorTuple = ['#B1EDD4', '#CAFAE7', '#E6FFF5'];
export const DARK_DATAVIS_10: ColorTuple = ['#B4B0FF', '#CCC9FF', '#E0DEFF'];
export const DARK_DATAVIS_11: ColorTuple = ['#FF9393', '#FDB0B0', '#FCBFBF'];
export const DARK_DATAVIS_12: ColorTuple = ['#B75757', '#BC6E6E', '#C18585'];
export const DARK_DATAVIS_13: ColorTuple = ['#D675BC', '#DF93CB', '#E4B2D6'];
export const DARK_DATAVIS_14: ColorTuple = ['#B6E1FF', '#C9F0F8', '#E6F7FA'];

export const DARK_DATA_VIS_TAB_BACKGROUND = '#002831';
export const DARK_DATA_VIS_DATA_VIOLET_HOVER = '#CCC9FF';
export const DARK_DATA_VIS_DATA_GREEN_40 = '#6DC6A1';
export const DARK_DATA_VIS_DATA_GREEN_20 = '#B1EDD4';
export const DARK_DATA_VIS_DATA_GREEN_20_HOVER = '#CAFCE7';

// Charts & Graphs
export const DARK_AXIS_LINES = '#9E9E9E';
export const DARK_GRID_LINES = '#424242';
export const DARK_TITLES_VALUES = '#F5F5F5';
