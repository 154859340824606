import { oldColors, oldVariables } from './oldColors';

// Currently the Welcome page is the only remaining page using this theme.
export const oldDarkTheme: any = {
  name: 'dark',
  oldColors,
  oldVariables: {
    // Background
    '--color-background': '#121212',
    '--color-on-background': '#fff',

    // Layers
    '--color-primary': oldColors.blue['200'],
    '--color-on-primary': '#fff',
    '--color-surface': '#2E2E2E', // Same color as DARK_MODAL_BACKGROUND
    '--color-on-surface': oldColors.slate['100'],
    '--color-surface-secondary': oldColors.slate['500'],
    '--color-on-surface-secondary': '#fff',
    '--color-surface-accent': oldColors.gray['300'],

    // Range
    '--color-range-background': oldColors.slate['300'],
    '--color-range-background-disabled': oldColors.slate['200'],
    '--color-range-input': 'var(--color-primary)',
    '--color-range-input-disabled': oldColors.slate['300'],
    '--color-range-handle-hover-border': 'rgb(150 225 243 / 50%)',
    '--color-range-handle-hover-border-disabled': 'rgb(200 200 200 / 50%)',

    // States
    '--color-success': '#4eda4e',
    '--color-error': 'red',
    '--color-warning': 'orange',
    '--color-disabled': oldColors.gray['200'],
    '--color-disabled-border': oldColors.gray['200'],
    '--color-on-disabled': oldColors.gray['300'],

    // Button
    '--color-button': oldColors.gray['200'],
    '--color-on-button': '#fff',
    '--color-button-secondary': '#fff',
    '--color-button-on-secondary': oldColors.slate['500'],
    '--color-button-secondary-hover': oldColors.slate['200'],
    '--color-button-secondary-focus': oldColors.slate['100'],

    // Graph Menu
    '--radial-menu-background': '#54616D',
    '--radial-menu-color': '#fff',
    '--radial-menu-border': '#7A8C9E',
    '--radial-menu-active-color': '#000',
    '--radial-menu-active-background': '#1DE9AC',

    // Headers
    '--color-headers': '#fff',
    '--color-connections-menu-icon': oldColors.blue['300'],

    // Divider
    '--color-divider': oldColors.gray['300'],

    // Input
    '--color-input': oldColors.gray['300'],
    '--color-input-border': 'transparent',
    '--color-input-border-hover': oldColors.gray['200'],
    '--color-input-border-focus': oldColors.slate['400'],
    '--color-on-input': oldColors.slate['100'],
    '--color-input-placeholder': oldColors.gray['100'],
    '--color-input-white': oldColors.gray['300'],

    // Backdrop
    '--color-layer-transparent': oldColors.gray['600'],

    // Drawer
    '--color-drawer': oldColors.gray['400'],

    // Chart Drag Handle
    '--color-handle-fill': '#fff',
    '--color-handle-stroke': 'var(--color-primary)',
    '--color-handle-drag-fill': 'transparent',
    '--color-handle-dots': '#8F979F',
    '--color-handle-line': '#8F979F',

    // Select
    '--select-input-border-radius': '5px',
    '--select-input-background': 'var(--color-input)',
    '--select-input-border': '1px solid transparent',
    '--select-input-color': 'var(--color-on-input)',
    '--select-input-spacing': '5px 5px',
    '--select-input-error': 'rgba(255, 0, 0, 0.5)',
    '--select-input-placeholder-color': 'var(--color-input-placeholder)',
    '--select-input-disabled-color': 'var(--color-disabled)',
    '--select-input-icon-color': 'rgb(145, 145, 145)',

    '--select-menu-background': oldColors.gray['400'],
    '--select-menu-border': `solid 1px var(--color-menu-border)`,
    '--select-menu-border-radius': 'var(--border-radius-md)',
    '--select-menu-item-color': 'white',
    '--select-menu-group-color': oldColors.gray['100'],
    '--select-menu-item-spacing': `5px 10px`,
    '--select-menu-item-active-background': oldColors.blue['400'],
    '--select-menu-item-active-color': 'white',
    '--select-menu-item-selected-color': 'white',
    '--select-menu-item-selected-background': oldColors.blue['400'],

    '--select-chip-background': oldColors.blue['400'],
    '--select-chip-border': 'transparent',
    '--select-chip-border-radius': 'var(--border-radius-lg)',
    '--select-chip-color': 'var(--color-on-primary)',
    '--select-chip-icon-color': 'var(--color-on-primary)',

    // Nav
    '--color-nav-background': oldColors.gray['600'],

    // Search
    '--color-search-primary': oldColors.slate['100'],
    '--color-search-secondary': oldColors.slate['200'],

    // Notifications
    '--notification-background': 'rgb(9, 9, 10, 0.9)',
    '--notification-color': '#fff',
    '--notification-border': 'transparent',
    '--notification-color-error': 'var(--color-error)',
    '--notification-color-warning': 'var(--color-warning)',
    '--notification-color-success': 'var(--color-success)',

    // Checkboxes
    '--color-checkbox-tree-higlight': oldColors.blue['400'],
    '--color-checkbox-tree-higlight-white': oldColors.blue['400'],
    '--color-checkbox-box-white': 'var(--color-on-primary)',

    // Menu
    '--color-menu-background': oldColors.gray['500'],
    '--color-menu-border': oldColors.gray['300'],
    '--color-list-disabled': oldColors.gray['200'],
    '--color-list-hover': oldColors.blue['400'],
    '--color-menu-icon': oldColors.blue['200'],

    // Table
    '--color-table-hover': oldColors.gray['400'],
    '--color-table-hover-flat': oldColors.gray['300'],
    '--color-table-header': oldColors.gray['100'],

    // Tabs
    '--color-tab-selected': oldColors.slate['100'],

    // Tooltip
    '--color-tooltip': 'rgb(0, 0, 0, 0.9)',
    '--color-on-tooltip': '#fff',

    // Chart Tooltip
    '--tooltip-background': 'rgb(0, 0, 0, 0.9)',
    '--tooltip-color': '#fff',
    '--tooltip-border-radius': '5px',
    '--tooltip-spacing': '5px',

    // Dialog
    '--dialog-background': oldColors.gray['500'],
    '--dialog-color': '#fff',

    // Histogram Chart
    '--color-histogram-stroke': oldColors.gray['300'],

    // Appearance Card
    '--color-theme-card-border': oldColors.gray['300'],

    // Split Panel
    '--focus-border': oldColors.blue['400'],
    '--separator-border': 'var(--color-background)',

    // Avatar
    '--avatar-initials-color': 'white',
    '--avatar-border': 'solid 1px var(--color-background)',
    '--avatar-group-spacing': 'calc(var(--spacing-sm) * -1)',

    ...oldVariables,
  },
};
