import CheckCircle from '@mui/icons-material/CheckCircle';
import Close from '@mui/icons-material/Close';
import Info from '@mui/icons-material/Info';
import { Alert, type AlertColor, Snackbar, Typography, Grid2 } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { MuiIconButton } from 'shared/elements/IconButton/MuiIconButton';

export type MuiSnackbarType = 'positive' | 'informative';

export interface MuiSnackbarProps {
  header?: string;
  message?: string;
  open: boolean;
  onClose: () => void;
  type?: MuiSnackbarType;
  action?: ReactNode;
  alert?: boolean;
}

export const MuiSnackbar: FC<MuiSnackbarProps> = ({ header, message, open, onClose, type, action, alert }) => {
  const messageNode = (
    <Grid2 container sx={{ flexDirection: 'column' }}>
      <Typography sx={{ fontWeight: 600, fontSize: '.875rem' }}>{header}</Typography>
      <Typography sx={{ fontSize: '.875rem' }}>{message}</Typography>
    </Grid2>
  );

  return (
    <Snackbar
      autoHideDuration={6000}
      open={open}
      message={messageNode}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      action={
        action || (
          <MuiIconButton
            icon={Close}
            onClick={onClose}
            size={'small'}
            sx={{ height: 24, width: 24 }}
            aria-label="close message"
          />
        )
      }
    >
      {alert ? (
        <Alert
          severity={type === 'positive' ? 'success' : ('info' as AlertColor)}
          icon={type === 'positive' ? <CheckCircle /> : <Info />}
          action={
            action || (
              <MuiIconButton
                icon={Close}
                onClick={onClose}
                size={'small'}
                sx={{ height: 24, width: 24 }}
                aria-label="close message"
              />
            )
          }
        >
          {message}
        </Alert>
      ) : undefined}
    </Snackbar>
  );
};
