import { withScope, captureException } from '@sentry/react';
import { type ErrorInfo, useCallback, useMemo } from 'react';
import { ErrorBoundary as ReactErrorBoundary, type ErrorBoundaryPropsWithComponent } from 'react-error-boundary';
import { ErrorComponent } from './ErrorComponent';
import { useLocation } from 'react-router';

export function ErrorBoundary({
  children,
  onError: onErrorOuter,
  FallbackComponent,
  onReset,
  resetKeys,
}: Partial<ErrorBoundaryPropsWithComponent>) {
  const { key } = useLocation();

  const onError = useCallback(
    (error: Error, info: ErrorInfo) => {
      if (import.meta.env.PROD) {
        withScope(scope => {
          scope.setTag('type', 'react');
          scope.setExtra('info', info);
          captureException(error);
        });
      }
      onErrorOuter?.(error, info);
    },
    [onErrorOuter]
  );

  FallbackComponent = FallbackComponent ?? ErrorComponent;

  /**
   * By default, key the error boundary on the current location key. This changes when the user navigates, even if it
   * is to the same route. If the key changes, the error boundary will reset. This prevents users from getting trapped
   * in an error state, unable to click the menu item to load the page again.
   */
  const resetKeysStable = useMemo(() => resetKeys ?? [key], [key, resetKeys]);

  return (
    <ReactErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={onError}
      onReset={onReset}
      resetKeys={resetKeysStable}
    >
      {children}
    </ReactErrorBoundary>
  );
}
