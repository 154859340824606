export const oldColors = {
  gray: {
    '100': '#ABAFBD',
    '200': '#818696',
    '300': '#474B56',
    '400': '#2E323C',
    '500': '#1E2026',
    '600': 'black',
  },
  slate: {
    '100': '#F2F9FB',
    '200': '#D8E6EA',
    '300': '#AACBD2',
    '400': '#7CA0AB',
    '500': '#2A6475',
    '600': '#004A5C',
  },
  blue: {
    '100': '#E9FBFF',
    '200': '#96E1F3',
    '300': '#13C2DD',
    '400': '#029DBB',
    '500': '#007492',
  },
  green: {
    '100': '#C6FFEB',
    '200': '#5AF8C8',
    '300': '#1DE9AC',
    '400': '#1ECB97',
  },
  yellow: {
    '100': '#FFF0B7',
    '200': '#FFE582',
    '300': '#FFDA4D',
    '400': '#FFCA00',
  },
  red: {
    '100': '#FFE5DF',
    '200': '#FFB6B6',
    '300': '#FA8080',
    '400': '#EA5656',
    '500': '#C22525',
  },
};

export const oldVariables = {
  // Spacing
  '--spacing-xs': '3px',
  '--spacing-sm': '5px',
  '--spacing-md': '15px',
  '--spacing-lg': '20px',
  '--spacing-xl': '30px',
  '--spacing-xxl': '50px',

  // Borders
  '--border-radius-sm': '4px',
  '--border-radius-md': '10px',
  '--border-radius-lg': '25px',

  // Shadows
  '--shadow-1': '0 4px 50px 0 rgba(42, 100, 117, 0.05)',
  '--shadow-2': '0 10px 12px 0 rgba(0, 74, 92, 0.03)',
  '--shadow-3': '0 10px 30px rgba(0, 0, 0, 0.25)',

  // Fonts
  '--font-family': `"Electrolux Sans", system-ui, -apple-system,BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,Noto Color Emoji`,
  '--mono-font-family': `Menlo, Monaco, "Andale Mono", "lucida console", "Courier New", monospace`,
};
